import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getNoogleStatistic, getNoogleStatisticGraphs } from '../actions';
import { Button, Container, Table, Spinner } from 'react-bootstrap';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import lineChart from '../../../assets/images/line-chart.png';
import borderChart from '../../../assets/images/growth.png';
import moment from 'moment';
import './Statistic.scss';
import { getAdvertisementOptions, getCompanyOptions, getGroupOptions } from '../../../core/helpers/functions';
import SelectSearch from '../../../components/UI/Select';
import SelectSearchMulti from '../../../components/UI/SelectIsMulti';
import { ReactComponent as Plus } from '../../../assets/images/plus.svg';
import { ReactComponent as Minus } from '../../../assets/images/minus.svg';

const Statistic = () => {
    const navigate = useNavigate();
    const { name } = useParams();
    const dispatch = useDispatch();
    const [stats, setStats] = useState(null);
    const [statsForGraph, setStatsForGraph] = useState(null);
    const [dateRange, setDateRange] = useState([moment().subtract(7, 'days').toDate(), moment().toDate()]);
    const [expandedCompanies, setExpandedCompanies] = useState({});
    const [expandedGroups, setExpandedGroups] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [selectedEntity, setSelectedEntity] = useState(''); // Обраний елемент (компанія/група/об'ява)
    const [selectedFields, setSelectedFields] = useState([]);
    const [selectedFieldsTwo, setSelectedFieldsTwo] = useState([]);
    const [selectedTypeGraph, setSelectedTypeGraph] = useState('line');
    const [showAllGoogleStats, setShowAllGoogleStats] = useState(false);
    const [showAllPPStats, setShowAllPPStats] = useState(false);

    const [startDate, endDate] = dateRange;

    const loadData = async (start, end) => {
        setIsLoading(true);
        const response = await dispatch(getNoogleStatistic(name, start, end));
        const graphs = await dispatch(getNoogleStatisticGraphs(name, start, end));
        if (response) {
            setStats(response.data);
        }

        if (graphs) {
            setStatsForGraph(graphs.data);
        }
        setIsLoading(false);
    };

    const handleApply = () => {
        setStats(null);
        loadData(startDate, endDate);
    };

    useEffect(() => {
        loadData(startDate, endDate);
    }, []);

    const companyOptions = getCompanyOptions(statsForGraph);
    const groupOptions = getGroupOptions(statsForGraph);
    const advertisementOptions = getAdvertisementOptions(statsForGraph);

    const fieldOptions = [
        { label: 'С2С', value: 'google.C2C' },
        { label: 'NET Income', value: 'google.NET_Income' },
        { label: 'ROI', value: 'google.ROI' },
        { label: 'Acc Budget', value: 'google.g_credit' },
        { label: 'Acc Budget USD', value: 'google.g_credit_usd' },
        { label: 'Budget', value: 'google.g_budget' },
        { label: 'Budget USD', value: 'google.g_budget_usd' },
        { label: 'Impressions', value: 'google.g_impressions' },
        { label: 'Clicks', value: 'google.g_clicks' },
        { label: 'CTR', value: 'google.g_CTR' },
        { label: 'Avg CPC', value: 'google.g_costPerClick' },
        { label: 'Cost', value: 'google.g_cost' },
        { label: 'Cost USD', value: 'google.g_cost_usd' },
        { label: 'Conversions', value: 'google.g_conversions' },
        { label: 'Avg conversion cost', value: 'google.g_costPerConversion' },
        { label: 'CR', value: 'google.g_CR' },
        { label: 'PP Views', value: 'pp.pp_views' },
        { label: 'PP Clicks', value: 'pp.pp_clicks' },
        { label: 'PP Block CTR', value: 'pp.pp_blockCTR' },
        { label: 'PP CR', value: 'pp.pp_CR' },
        { label: 'PP Leads', value: 'pp.pp_leads' },
        { label: 'PP Leads Approved', value: 'pp.pp_leadsApproved' },
        { label: 'PP Leads Pending', value: 'pp.pp_leadsPending' },
        { label: 'PP Leads Rejected', value: 'pp.pp_leadsRejected' },
        { label: 'PP Leads Trash', value: 'pp.pp_leadsTrash' },
        { label: 'PP CPC', value: 'pp.pp_CPC' },
        { label: 'PP CPM', value: 'pp.pp_CPM' },
        { label: 'PP Profit', value: 'pp.pp_profit' },
    ];

    const entityOptions = [
        ...companyOptions.map((option) => ({ label: `Комания: ${option.label}`, value: option.value })),
        ...groupOptions.map((option) => ({ label: `Группа: ${option.label}`, value: option.value })),
        ...advertisementOptions.map((option) => ({ label: `Обьявление: ${option.label}`, value: option.value })),
    ];

    const handleEntityChange = (selected) => {
        setSelectedEntity(selected);
        // setSelectedFields([]);
    };

    const calculateROI = (profit, cost) => {
        if (profit === 0 && cost === 0) return 0;
        return (((profit - cost) / cost) * 100).toFixed(2);
    };

    const getEntityData = () => {
        if (selectedEntity) {
            const data = statsForGraph
                .map((item) => {
                    if (item.company.name === selectedEntity) {
                        return item.company.total;
                    }

                    if (item.company.adGroups.find((group) => group.name === selectedEntity)) {
                        return item.company.adGroups.find((group) => group.name === selectedEntity).total;
                    }

                    if (item.company.adGroups.map((group) => group.ads.find((ad) => ad.id === selectedEntity))) {
                        return item.company.adGroups
                            .map((group) => {
                                return group.ads.find((ad) => ad.id === selectedEntity);
                            })
                            .filter((element) => element !== undefined)
                            .flat();
                    }
                })
                .flat();

            const calculateData = data.map((item) => ({
                ...item,
                google: { ...item.google, ROI: calculateROI(item.pp.pp_profit, item.google.g_cost_usd) },
            }));
            return calculateData;
        }

        return null;
    };

    const chartOptions = {
        chart: { type: selectedTypeGraph },
        title: { text: `График для ${selectedEntity}` },
        xAxis: { categories: statsForGraph?.map((stat) => stat.date) },
        yAxis: [
            {
                title: { text: 'Значение левая ось' },
                opposite: false,
            },
            {
                title: { text: 'Значение правая ось' },
                opposite: true,
            },
        ],
        series: [
            ...selectedFields.map((field, index) => {
                const [source, key] = field.split('.');
                const entyty = getEntityData();
                return {
                    name: fieldOptions.find((item) => item.value === field).label,
                    data: entyty?.map((stat) => {
                        const number = Number(stat[source]?.[key]) || 0;
                        return Number(number.toFixed(2));
                    }),
                    yAxis: 0,
                };
            }),
            ...selectedFieldsTwo.map((field, index) => {
                const [source, key] = field.split('.');
                const entyty = getEntityData();
                return {
                    name: fieldOptions.find((item) => item.value === field).label,
                    data: entyty?.map((stat) => {
                        const number = Number(stat[source]?.[key]) || 0;
                        return Number(number.toFixed(2));
                    }),
                    yAxis: 1,
                };
            }),
        ],
    };

    const toggleCompany = (companyName) => {
        setExpandedCompanies((prevState) => ({
            ...prevState,
            [companyName]: !prevState[companyName],
        }));
    };

    const toggleGroup = (companyName, groupName) => {
        setExpandedGroups((prevState) => ({
            ...prevState,
            [`${companyName}_${groupName}`]: !prevState[`${companyName}_${groupName}`],
        }));
    };

    const fixedNumber = (number) => {
        if (number === null || number === undefined) return '-';
        return Number.isInteger(number) ? number : number.toFixed(2);
    };

    const factoryColorTextС2С = (number) => {
        if (typeof number !== 'number') return '';
        if (number >= 1) return 'text-success text-bold';
        if (number < 1) return 'text-danger text-bold';
    };

    const factoryColorTextIncome = (number) => {
        if (typeof number !== 'number') return '';
        if (number >= 0) return 'text-success text-bold';
        if (number < 0) return 'text-danger text-bold';
    };

    const factoryColorTextRoi = (number) => {
        const num = Number(number);
        if (typeof num !== 'number') return '';
        if (num > 50) return 'text-success text-bold';
        if (num >= 0 && num <= 50) return 'warning-text text-bold';
        if (num < 0) return 'text-danger text-bold';
    };

    if (isLoading) {
        return (
            <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </Container>
        );
    }

    return (
        <div className="Statistic">
            <h1 className="Statistic-title">Статистика - {name}</h1>
            <div className="Statistic-header">
                <Button variant="secondary" onClick={() => navigate(-1)} className="mb-4">
                    Назад
                </Button>
                <div className="mb-4 d-flex align-items-center">
                    <DatePicker
                        maxDate={new Date()}
                        selected={startDate}
                        onChange={(dates) => {
                            const [start, end] = dates;
                            setDateRange([start, end]);
                        }}
                        className="DatePicker"
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                    />
                    <Button variant="primary" className="ml-3" onClick={handleApply}>
                        Применить
                    </Button>
                </div>
            </div>
            {!stats ? (
                <div className="Statistic-no-stats">No Stats</div>
            ) : (
                <Table bordered hover responsive className="text-center Statistic-table">
                    <thead>
                        <tr className="Statistic-table-tr">
                            <th></th>
                            <th>С2С</th>
                            <th>NET Income</th>
                            <th>ROI</th>
                            <th className="google-stat">CTR</th>
                            <th className="google-stat">Cost USD</th>
                            <th className="google-stat">Conversions</th>
                            <th className="google-stat">
                                CR
                                {!showAllGoogleStats && (
                                    <div className="icon-plus" onClick={() => setShowAllGoogleStats(!showAllGoogleStats)}>
                                        <Plus />
                                    </div>
                                )}
                            </th>
                            {showAllGoogleStats && (
                                <>
                                    <th className="google-stat">Currency</th>
                                    <th className="google-stat">Acc Budget</th>
                                    <th className="google-stat">Acc Budget USD</th>
                                    <th className="google-stat">Budget</th>
                                    <th className="google-stat">Budget USD</th>
                                    <th className="google-stat">Impressions</th>
                                    <th className="google-stat">Clicks</th>
                                    <th className="google-stat">Avg CPC</th>
                                    <th className="google-stat">Cost</th>
                                    <th className="google-stat">
                                        Avg conversion cost
                                        {showAllGoogleStats && (
                                            <div className="icon-plus" onClick={() => setShowAllGoogleStats(!showAllGoogleStats)}>
                                                <Minus />
                                            </div>
                                        )}
                                    </th>
                                </>
                            )}
                            <th className="pp-stat">PP CR</th>
                            <th className="pp-stat">PP Leads</th>
                            <th className="pp-stat">PP Leads Approved</th>
                            <th className="pp-stat">
                                PP Profit
                                {!showAllPPStats && (
                                    <div className="icon-plus" onClick={() => setShowAllPPStats(!showAllPPStats)}>
                                        <Plus />
                                    </div>
                                )}
                            </th>
                            {showAllPPStats && (
                                <>
                                    <th className="pp-stat">PP Views</th>
                                    <th className="pp-stat">PP Clicks</th>
                                    <th className="pp-stat">PP Block CTR</th>
                                    <th className="pp-stat">PP Leads Pending</th>
                                    <th className="pp-stat">PP Leads Rejected</th>
                                    <th className="pp-stat">PP Leads Trash</th>
                                    <th className="pp-stat">PP CPC</th>
                                    <th className="pp-stat">
                                        PP CPM
                                        {showAllPPStats && (
                                            <div className="icon-plus" onClick={() => setShowAllPPStats(!showAllPPStats)}>
                                                <Minus />
                                            </div>
                                        )}
                                    </th>
                                </>
                            )}
                        </tr>
                    </thead>
                    <tbody className="table-body-wrapper">
                        {stats?.companies.map((company) => (
                            <React.Fragment key={company.name}>
                                <tr className="table-body-tr" style={{ background: '#B4C4DD' }}>
                                    <td
                                        style={{ minWidth: '200px', textAlign: 'left' }}
                                        className={`Statistic-table-tr with-arrow ${expandedCompanies[company.name] ? 'expanded' : ''}`}
                                        onClick={() => toggleCompany(company.name)}
                                    >
                                        {company.name}
                                    </td>
                                    <td className={factoryColorTextС2С(company?.total?.google.C2C)}>
                                        {fixedNumber(company?.total?.google.C2C) ?? '-'}
                                    </td>
                                    <td className={factoryColorTextIncome(company?.total?.google.NET_Income)}>
                                        {fixedNumber(company?.total?.google.NET_Income) ?? '-'}
                                    </td>
                                    <td
                                        className={factoryColorTextRoi(
                                            calculateROI(company.total.pp.pp_profit, company.total.google.g_cost_usd),
                                        )}
                                    >
                                        {calculateROI(company.total.pp.pp_profit, company.total.google.g_cost_usd) ?? '-'} %
                                    </td>
                                    <td>{fixedNumber(company.total.google.g_CTR)}</td>
                                    <td>{fixedNumber(company.total.google.g_cost_usd)} $</td>
                                    <td>{fixedNumber(company.total.google.g_conversions)}</td>
                                    <td>{fixedNumber(company.total.google.g_CR)}</td>

                                    {showAllGoogleStats && (
                                        <>
                                            <td>{company.total?.g_currency}</td>
                                            <td>{fixedNumber(company.total?.google.g_credit) ?? '-'}</td>
                                            <td>{fixedNumber(company.total?.google.g_credit_usd) ?? '-'} $</td>
                                            <td>{fixedNumber(company.total.google.g_budget)}</td>
                                            <td>{fixedNumber(company.total.google.g_budget_usd)} $</td>
                                            <td>{fixedNumber(company.total.google.g_impressions)}</td>
                                            <td>{fixedNumber(company.total.google.g_clicks)}</td>
                                            <td>{fixedNumber(company.total.google.g_costPerClick) ?? '-'}</td>
                                            <td>{fixedNumber(company.total.google.g_cost)}</td>
                                            <td>{fixedNumber(company.total.google.g_costPerConversion)}</td>
                                        </>
                                    )}

                                    <td>{fixedNumber(company?.total?.pp.pp_CR) ?? 0}</td>
                                    <td>{fixedNumber(company?.total?.pp.pp_leads) ?? 0}</td>
                                    <td>{fixedNumber(company?.total?.pp.pp_leadsApproved) ?? 0}</td>
                                    <td>{fixedNumber(company?.total?.pp.pp_profit) ?? 0}</td>

                                    {showAllPPStats && (
                                        <>
                                            <td>{fixedNumber(company?.total?.pp.pp_views) ?? 0}</td>
                                            <td>{fixedNumber(company?.total?.pp.pp_clicks) ?? 0}</td>
                                            <td>{fixedNumber(company?.total?.pp.pp_blockCTR) ?? 0}</td>
                                            <td>{fixedNumber(company?.total?.pp.pp_leadsPending) ?? 0}</td>
                                            <td>{fixedNumber(company?.total?.pp.pp_leadsRejected) ?? 0}</td>
                                            <td>{fixedNumber(company?.total?.pp.pp_leadsTrash) ?? 0}</td>
                                            <td>{fixedNumber(company?.total?.pp.pp_CPC) ?? 0}</td>
                                            <td>{fixedNumber(company?.total?.pp.pp_CPM) ?? 0}</td>
                                        </>
                                    )}
                                </tr>
                                {expandedCompanies[company.name] && (
                                    <>
                                        {company.adGroups.map((group) => (
                                            <React.Fragment key={group.name}>
                                                <tr className="table-body-tr" style={{ background: '#B4C4DD96' }}>
                                                    <td
                                                        style={{ minWidth: '200px', textAlign: 'left' }}
                                                        className={`Statistic-table-tr with-arrow pl-3 ${expandedGroups[`${company.name}_${group.name}`] ? 'expanded' : ''}`}
                                                        onClick={() => toggleGroup(company.name, group.name)}
                                                    >
                                                        {group.name ?? 'Group'}
                                                    </td>
                                                    <td className={factoryColorTextС2С(group?.total?.google.C2C)}>
                                                        {fixedNumber(group?.total?.google.C2C) ?? '-'}
                                                    </td>
                                                    <td className={factoryColorTextIncome(group?.total?.google.NET_Income)}>
                                                        {fixedNumber(group?.total?.google.NET_Income) ?? '-'}
                                                    </td>
                                                    <td
                                                        className={factoryColorTextRoi(
                                                            calculateROI(group.total.pp.pp_profit, group.total.google.g_cost_usd),
                                                        )}
                                                    >
                                                        {calculateROI(group.total.pp.pp_profit, group.total.google.g_cost_usd) ??
                                                            '-'}{' '}
                                                        %
                                                    </td>
                                                    <td>{fixedNumber(group.total.google.g_CTR)}</td>
                                                    <td>{fixedNumber(group.total.google.g_cost_usd)} $</td>
                                                    <td>{fixedNumber(group.total.google.g_conversions)}</td>
                                                    <td>{fixedNumber(group.total.google.g_CR)}</td>

                                                    {showAllGoogleStats && (
                                                        <>
                                                            <td>{group.total?.g_currency}</td>
                                                            <td>{fixedNumber(group.total?.google.g_credit) ?? '-'}</td>
                                                            <td>{fixedNumber(group.total?.google.g_credit_usd) ?? '-'} $</td>
                                                            <td>{fixedNumber(group.total.google.g_budget)}</td>
                                                            <td>{fixedNumber(group.total.google.g_budget_usd)} $</td>
                                                            <td>{fixedNumber(group.total.google.g_impressions)}</td>
                                                            <td>{fixedNumber(group.total.google.g_clicks)}</td>
                                                            <td>{fixedNumber(group.total.google.g_costPerClick) ?? '-'}</td>
                                                            <td>{fixedNumber(group.total.google.g_cost)}</td>
                                                            <td>{fixedNumber(group.total.google.g_costPerConversion)}</td>
                                                        </>
                                                    )}

                                                    <td>{fixedNumber(group?.total?.pp.pp_CR) ?? 0}</td>
                                                    <td>{fixedNumber(group?.total?.pp.pp_leads) ?? 0}</td>
                                                    <td>{fixedNumber(group?.total?.pp.pp_leadsApproved) ?? 0}</td>
                                                    <td>{fixedNumber(group?.total?.pp.pp_profit) ?? 0}</td>

                                                    {showAllPPStats && (
                                                        <>
                                                            <td>{fixedNumber(group?.total?.pp.pp_views) ?? 0}</td>
                                                            <td>{fixedNumber(group?.total?.pp.pp_clicks) ?? 0}</td>
                                                            <td>{fixedNumber(group?.total?.pp.pp_blockCTR) ?? 0}</td>
                                                            <td>{fixedNumber(group?.total?.pp.pp_leadsPending) ?? 0}</td>
                                                            <td>{fixedNumber(group?.total?.pp.pp_leadsRejected) ?? 0}</td>
                                                            <td>{fixedNumber(group?.total?.pp.pp_leadsTrash) ?? 0}</td>
                                                            <td>{fixedNumber(group?.total?.pp.pp_CPC) ?? 0}</td>
                                                            <td>{fixedNumber(group?.total?.pp.pp_CPM) ?? 0}</td>
                                                        </>
                                                    )}
                                                </tr>
                                                {expandedGroups[`${company.name}_${group.name}`] && (
                                                    <>
                                                        {group.ads.map((ads) => (
                                                            <tr className="table-body-tr" key={ads.id} className="Statistic-ads">
                                                                <td
                                                                    style={{
                                                                        minWidth: '200px',
                                                                        paddingLeft: '30px',
                                                                        textAlign: 'left',
                                                                    }}
                                                                >
                                                                    {ads.id}
                                                                </td>
                                                                <td className={factoryColorTextС2С(ads?.google?.C2C)}>
                                                                    {fixedNumber(ads?.google?.C2C) ?? '-'}
                                                                </td>
                                                                <td className={factoryColorTextIncome(ads?.google?.NET_Income)}>
                                                                    {fixedNumber(ads?.google?.NET_Income) ?? '-'}
                                                                </td>
                                                                <td
                                                                    className={factoryColorTextRoi(
                                                                        calculateROI(ads.pp.pp_profit, ads.google.g_cost_usd),
                                                                    )}
                                                                >
                                                                    {calculateROI(ads.pp.pp_profit, ads.google.g_cost_usd) ?? '-'}{' '}
                                                                    %
                                                                </td>
                                                                <td className="google-stat">{fixedNumber(ads.google.g_CTR)}</td>
                                                                <td className="google-stat">
                                                                    {fixedNumber(ads.google.g_cost_usd)} $
                                                                </td>
                                                                <td className="google-stat">
                                                                    {fixedNumber(ads.google.g_conversions)}
                                                                </td>
                                                                <td className="google-stat">{fixedNumber(ads.google.g_CR)}</td>

                                                                {showAllGoogleStats && (
                                                                    <>
                                                                        <td className="google-stat">{ads.google?.g_currency}</td>
                                                                        <td className="google-stat">
                                                                            {fixedNumber(ads.google?.g_credit) ?? '-'}
                                                                        </td>
                                                                        <td className="google-stat">
                                                                            {fixedNumber(ads.google?.g_credit_usd) ?? '-'} $
                                                                        </td>
                                                                        <td className="google-stat">
                                                                            {fixedNumber(ads.google.g_budget)}
                                                                        </td>
                                                                        <td className="google-stat">
                                                                            {fixedNumber(ads.google.g_budget_usd)} $
                                                                        </td>
                                                                        <td className="google-stat">
                                                                            {fixedNumber(ads.google.g_impressions)}
                                                                        </td>
                                                                        <td className="google-stat">
                                                                            {fixedNumber(ads.google.g_clicks)}
                                                                        </td>
                                                                        <td className="google-stat">
                                                                            {fixedNumber(ads.google.g_costPerClick) ?? '-'}
                                                                        </td>
                                                                        <td className="google-stat">
                                                                            {fixedNumber(ads.google.g_cost)}
                                                                        </td>
                                                                        <td className="google-stat">
                                                                            {fixedNumber(ads.google.g_costPerConversion)}
                                                                        </td>
                                                                    </>
                                                                )}

                                                                <td className="pp-stat">{fixedNumber(ads?.pp?.pp_CR) ?? 0}</td>
                                                                <td className="pp-stat">{fixedNumber(ads?.pp?.pp_leads) ?? 0}</td>
                                                                <td className="pp-stat">
                                                                    {fixedNumber(ads?.pp?.pp_leadsApproved) ?? 0}
                                                                </td>
                                                                <td className="pp-stat">
                                                                    {fixedNumber(ads?.pp?.pp_profit) ?? 0}
                                                                </td>

                                                                {showAllPPStats && (
                                                                    <>
                                                                        <td className="pp-stat">
                                                                            {fixedNumber(ads?.pp?.pp_views) ?? 0}
                                                                        </td>
                                                                        <td className="pp-stat">
                                                                            {fixedNumber(ads?.pp?.pp_clicks) ?? 0}
                                                                        </td>
                                                                        <td className="pp-stat">
                                                                            {fixedNumber(ads?.pp?.pp_blockCTR) ?? 0}
                                                                        </td>
                                                                        <td className="pp-stat">
                                                                            {fixedNumber(ads?.pp?.pp_leadsPending) ?? 0}
                                                                        </td>
                                                                        <td className="pp-stat">
                                                                            {fixedNumber(ads?.pp?.pp_leadsRejected) ?? 0}
                                                                        </td>
                                                                        <td className="pp-stat">
                                                                            {fixedNumber(ads?.pp?.pp_leadsTrash) ?? 0}
                                                                        </td>
                                                                        <td className="pp-stat">
                                                                            {fixedNumber(ads?.pp?.pp_CPC) ?? 0}
                                                                        </td>
                                                                        <td className="pp-stat">
                                                                            {fixedNumber(ads?.pp?.pp_CPM) ?? 0}
                                                                        </td>
                                                                    </>
                                                                )}
                                                            </tr>
                                                        ))}
                                                    </>
                                                )}
                                            </React.Fragment>
                                        ))}
                                    </>
                                )}
                            </React.Fragment>
                        ))}
                    </tbody>
                </Table>
            )}

            {statsForGraph?.length !== 0 && (
                <div className="mt-2 d-flex flex-column gap-3">
                    <div className="d-flex align-items-center gap-2">
                        <SelectSearch
                            options={entityOptions}
                            itemLabel="label"
                            itemValue="value"
                            default={selectedEntity}
                            onChange={handleEntityChange}
                            de
                            value={selectedEntity}
                            title="Выберите компанию, группу или объявление"
                        />
                        {selectedEntity && (
                            <SelectSearchMulti
                                options={fieldOptions}
                                itemLabel="label"
                                itemValue="value"
                                onChange={setSelectedFields}
                                values={selectedFields}
                                title="Выберите поля для левой оси"
                            />
                        )}
                        {selectedEntity && (
                            <SelectSearchMulti
                                options={fieldOptions}
                                itemLabel="label"
                                itemValue="value"
                                onChange={setSelectedFieldsTwo}
                                values={selectedFieldsTwo}
                                title="Выберите поля для правой оси"
                            />
                        )}
                        <div
                            onClick={() => setSelectedTypeGraph('line')}
                            className={`Statistic-icon-graphs ${selectedTypeGraph === 'line' && 'active'}`}
                        >
                            <img src={lineChart} alt="" />
                        </div>
                        <div
                            onClick={() => setSelectedTypeGraph('column')}
                            className={`Statistic-icon-graphs ${selectedTypeGraph === 'column' && 'active'}`}
                        >
                            <img src={borderChart} alt="" />
                        </div>

                        <div className="Statistic-days-filter">
                            <div className={`Statistic-icon-graphs active`}>D</div>
                            <div className={`Statistic-icon-graphs`}>W</div>
                            <div className={`Statistic-icon-graphs`}>M</div>
                        </div>
                    </div>
                    {statsForGraph && (selectedFields.length > 0 || selectedFieldsTwo.length > 0) && (
                        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                    )}
                </div>
            )}
        </div>
    );
};

export default Statistic;
