import './Maintenance.scss';
import icon from '../../assets/images/maintenance.png';


const Maintenance = () => {


    return (
        <div className='Maintenance'>
            <img src={icon} alt=""/>
        </div>
    )
}


export default Maintenance;