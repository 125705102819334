import { Button, Card, Spinner } from 'react-bootstrap';
import './styles.scss';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ReactComponent as Save } from '../../assets/images/save.svg';
import { downloadIntegration, editFilterRules, getFilters, getLandings, getStaticLanding, saveLandings } from './actions';
import RulesBinom from '../../components/Binom/RulesBinom';
import Services from '../../components/Binom/Services';
import Landings from '../../components/Binom/Landings';
import { NotificationManager } from 'react-notifications';
import { ReactComponent as Question } from '../../assets/images/question.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { setWhitePageDomain } from '../Farmer/actions';
import { ROUTES } from '../../core/helpers/routes';

const Filters = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingSaving, setIsLoadingSaving] = useState(null);
    const [rules, setRules] = useState(null);
    const [ourСloak, setOurCloal] = useState(null);
    const [landings, setLandings] = useState(null);
    const [optionsLandings, setOptionsLandings] = useState([]);

    const loadDataStatic = async () => {
        const resp = await dispatch(getStaticLanding(params?.id));
        const resultArray = Object.entries(resp).map(([key, value]) => ({
            label: key,
            value: value,
        }));

        setOptionsLandings(resultArray);
    };

    const params = useParams();
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const loadData = async () => {
        setIsLoading(true);
        const resp = await dispatch(getFilters(params?.id));
        // const landing = await dispatch(getLandings(params?.id));

        if (resp) {
            const ourRules = resp.data.slice(1);
            const cloack = resp.data.slice(0, 1);
            setRules(ourRules);
            setOurCloal(cloack);
        } else {
            navigate(-1);
            return;
        }

        // if (landing) {
        //     setLandings(landing.data);
        // }
        await loadDataStatic();

        setIsLoading(false);
    };

    useEffect(() => {
        loadData();
    }, []);

    // const handleClickDownload = async () => {
    //     try {
    //         const resp = await dispatch(downloadIntegration(params.id));
    //         if (resp && resp.data) {
    //             const blob = new Blob([resp.data], { type: 'application/zip' });
    //
    //             const url = window.URL.createObjectURL(blob);
    //
    //             const link = document.createElement('a');
    //             link.href = url;
    //             link.setAttribute('download', 'file.zip');
    //
    //             document.body.appendChild(link);
    //
    //             link.click();
    //
    //             document.body.removeChild(link);
    //             window.URL.revokeObjectURL(url);
    //         } else {
    //             console.error('Download failed: No data received');
    //         }
    //     } catch (error) {
    //         console.error('Error downloading the file:', error);
    //     }
    // };

    const hasInvalidData = (rules) => {
        const checkResultPage = (resultPage) => {
            return resultPage.some((element) => element.data.length === 0 || element.data === 'http://');
        };

        const processRules = (rules) => {
            for (let item of rules) {
                if (item.result_page && checkResultPage(item.result_page)) {
                    return true;
                }

                if (item.rules && processRules(item.rules)) {
                    return true;
                }
            }
            return false;
        };

        return processRules(rules);
    };

    const processRules = (rules) => {
        return rules.map((item, index) => {
            const rule = {
                active: item.active,
                name: item.name,
                default: item.default,
                result: item.result,
                position: index + 1,
                result_split: item.result_split,
                target: item.target,
                filters: item.filters?.map((el, indexFilter) => {
                    const filter = {
                        active: el.active,
                        get_data_method_id: el.filter?.id,
                        operator_method_id: el.operator.id,
                        value: el.value,
                        position: indexFilter + 1,
                    };
                    if (el.id && typeof el.id === 'number') {
                        filter.id = el.id;
                    }
                    return filter;
                }),
            };

            if (item.result_page) {
                rule.result_page = item.result_page.map((element, indexLand) => ({
                    ...element,
                    position: indexLand + 1,
                }));
            }

            if (item.rules) {
                rule.rules = processRules(item.rules);
            }

            if (item.id) {
                rule.id = item.id;
            }

            return rule;
        });
    };

    const handleClickButtonSetServer = async () => {
        const domain = params?.id;
        await dispatch(setWhitePageDomain(domain));
    };

    const saveRules = async (type) => {
        setIsLoadingSaving(type);
        const findedErrors = rules.find((item) => !item.filters || item.filters.length === 0);
        const cloaack = ourСloak.map((item) => ({ ...item, default: 1 }));
        const rulesOptions = [...cloaack, ...rules];

        const hasError = hasInvalidData(rulesOptions);
        if (findedErrors) {
            NotificationManager.error(`В Rules должно быть как минимум одна Criteria и один Landing`);
            return;
        }

        if (hasError) {
            NotificationManager.error(`Название Landing не может быть http:// или пустое значение `);
            return;
        }

        const options = {
            rules: processRules(rulesOptions),
        };

        // const optionsLanding = {
        //     ...landings,
        //     default_result_page: landings.default_result_page.map((item, index) => ({
        //         ...item,
        //         position: index + 1,
        //     })),
        // };
        // await dispatch(saveLandings(optionsLanding, params?.id));
        const resp = await dispatch(editFilterRules(options, params?.id));

        if (resp && type === 'upload') {
            navigate(ROUTES.panelBayer.link);
            await handleClickButtonSetServer();
        }
        setIsLoadingSaving(null);
    };

    const showInfoDownload = () => {
        return (
            <div className="info_norm_buyer">
                <Question className="question-icon" data-tooltip-id="question-tooltip-download" />
                <Tooltip
                    id="question-tooltip-download"
                    place="bottom"
                    style={{
                        zIndex: '9999999999',
                        margin: 0,
                        padding: '10px',
                        width: '250px',
                    }}
                >
                    <div className="info_norm_buyer-content">
                        <p className="m-0 p-0 w-100">
                            Save - сохраняет текущие изменения фильтров домена в системе, они попадут на сервер после загрузки.
                        </p>
                        <p className="m-0 p-0 w-100">
                            Save&Upload - сохраняет текущие изменения фильтров домена в системе, после чего активирует загрузку сохраненной версии на сервер.
                            Примечание: при активации загрузки выполняется полная выгрузка всех файлов на сервер, а не только фильтрации.
                        </p>
                    </div>
                </Tooltip>
            </div>
        );
    };

    return (
        <Card className="wrapper">
            <Card.Header>
                <div className="d-flex align-items-center justify-content-between pl-5 pr-5 ml-1 mr-2">
                    <div className="d-flex gap-2 justify-content-between w-100 align-items-center">
                        <div className="d-flex align-items-center gap-2">
                            <button onClick={() => navigate(-1)} className="wrapper-button-added-path-button mb-2">
                                <span>Back</span>
                            </button>
                            <h5>Filtering</h5>
                        </div>
                        <div className="d-flex gap-2 align-items-center">
                            {showInfoDownload()}
                            <button
                                onClick={() => saveRules('save')}
                                style={{ width: '82px' }}
                                className="wrapper-button-added-path-button"
                            >
                                {isLoadingSaving === 'save' ? (
                                    <Spinner animation="border" size="sm" />
                                ) : (
                                    <>
                                        {' '}
                                        <span>Save</span>
                                        <Save />
                                    </>
                                )}
                            </button>
                            <button
                                onClick={() => saveRules('upload')}
                                style={{ width: '132px' }}
                                className="wrapper-button-added-path-button"
                            >
                                {isLoadingSaving === 'upload' ? (
                                    <Spinner animation="border" size="sm" />
                                ) : (
                                    <>
                                        {' '}
                                        <span>Save&Upload</span>
                                        <Save />
                                    </>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </Card.Header>
            <Card.Body>
                {!isLoading && rules !== null && (
                    <div className="Forms">
                        <div className="Right-form">
                            <Services />
                            {/*<RulesBinom*/}
                            {/*    rules={ourСloak}*/}
                            {/*    setRules={setOurCloal}*/}
                            {/*    ourСloak={true}*/}
                            {/*    optionsLandings={optionsLandings}*/}
                            {/*/>*/}
                            <RulesBinom rules={rules} setRules={setRules} optionsLandings={optionsLandings} />
                            {/*<Landings landings={landings} setLandings={setLandings} optionsLandings={optionsLandings} />*/}
                        </div>
                    </div>
                )}
            </Card.Body>
        </Card>
    );
};

export default Filters;
