import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ROUTES } from '../core/helpers/routes';
import Register from '../pages/Register';
import Template from '../components/Templates';
import docCookies from '../core/services/cookie';
import { renderRoutesWithRole } from '../utils/roleLOgin';
import Filters from '../pages/Filters';
import Login from '../pages/Login';
import Maintenance from "../pages/Maintenance";

export const AppRouter = () => {
    const token = docCookies.getItem('token');
    const isStatus = JSON.parse(localStorage.getItem('isMaintenance'))
    const isMaintenance = process.env.REACT_APP_MAINTENANCE_MODE;


    if(isMaintenance === 'true' && isStatus !== 1) return (
        <BrowserRouter>
            <Routes>
                <Route path={ROUTES.index.main} element={<Navigate to={ROUTES.maintenance.link} />} />
                <Route path={ROUTES.maintenance.path} element={<Maintenance />} />
                <Route path="/*" element={<Navigate to={ROUTES.maintenance.link} replace />} />
            </Routes>
        </BrowserRouter>
    )
    return (
        <>
            {!token ? (
                <BrowserRouter>
                    <Routes>
                        <Route path={ROUTES.index.main} element={<Navigate to={ROUTES.authorization.link} />} />
                        <Route path={ROUTES.authorization.path} element={<Login />} />
                        <Route path={ROUTES.register.path} element={<Register />} />
                        <Route path="/*" element={<Navigate to={ROUTES.authorization.link} replace />} />
                    </Routes>
                </BrowserRouter>
            ) : (
                <BrowserRouter>
                    <Routes>
                        {renderRoutesWithRole().length !== 0 && (
                            <Route path={ROUTES.index.main} element={<Navigate to={renderRoutesWithRole()[0].link} />} />
                        )}
                        {renderRoutesWithRole().map((item) => (
                            <Route path={item.link} element={<Template>{item.component}</Template>} />
                        ))}
                        <Route path="/*" element={<Navigate to="/" replace />} />
                        <Route
                            path="/filter/:id"
                            element={
                                <Template>
                                    <Filters />
                                </Template>
                            }
                        />
                    </Routes>
                </BrowserRouter>
            )}
        </>
    );
};
