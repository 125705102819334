import { Button, Modal, Row, Spinner } from 'react-bootstrap';
import './styles.scss';
import { useEffect, useState } from 'react';
import { getFreeAccount, getMessageAccount, verifyAccount } from '../../../pages/AggregatorMail/actions';
import { useDispatch } from 'react-redux';
import { ReactComponent as InfoNorm } from '../../../assets/images/infoNorm.svg';

const ModalCreateAccount = ({ closeModal, show, pendingEmail, loadData }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
    const [step, setStep] = useState(1);
    const [freeAccount, setFreeAccount] = useState(null);
    const [messages, setMessages] = useState([]);

    const dispatch = useDispatch();

    const closedModal = () => {
        closeModal();
    };

    const handleClickButton = async () => {
        setIsLoading(true);
        const resp = await dispatch(verifyAccount(freeAccount?.id));
        if (resp) {
            closedModal();
            loadData();
        }
        setIsLoading(false);
    };

    const handleClickUpdate = async () => {
        setIsLoadingUpdate(true);
        const readMessages = await dispatch(getMessageAccount(freeAccount.id));
        if (readMessages) {
            setMessages(readMessages.data);
        }
        setIsLoadingUpdate(false);
    };

    const showTitleButton = () => {
        switch (step) {
            case 1:
                return 'Запросить свободный Email';
            case 2:
                return 'Подтвердить правильность пересылки';
            default:
                return '';
        }
    };

    const handleClickGetFreeEmailAccount = async () => {
        setIsLoading(true);
        const account = await dispatch(getFreeAccount());

        if (account) {
            setFreeAccount(account);
            setStep(2);
        }

        setIsLoading(false);
    };

    const factoryActionButton = () => {
        switch (step) {
            case 1:
                return handleClickGetFreeEmailAccount;
            case 2:
                return handleClickButton;
            default:
                return '';
        }
    };

    useEffect(() => {
        if (pendingEmail) {
            setFreeAccount(pendingEmail);
            setStep(2);
        }
    }, []);

    const handleClickInfo = () => {
        const url = '/info-setting-forwarding';
        window.open(url, '_blank');
    };

    return (
        <Modal show={show} onHide={closedModal} className="modal-create-account">
            <Modal.Header closeButton={step === 1 ? true : false}>
                <Modal.Title className="d-flex align-items-center gap-2 justify-content-between w-100">
                    Добавить акаунт{' '}
                    {step === 2 && (
                        <Button
                            style={{ minWidth: '94px', height: '30px' }}
                            className="wrapper-button-added-path-button"
                            variant="primary"
                            onClick={handleClickUpdate}
                        >
                            {isLoadingUpdate ? <Spinner size="sm" animation="border" /> : 'Обновить'}
                        </Button>
                    )}
                </Modal.Title>
            </Modal.Header>
            <div className="d-flex flex-column gap-3 p-4">
                <Row className="CreateAccount">
                    {step === 1 && (
                        <div className="CreateAccount-preview-description">Для начала нужно "Запросить свободный Email"</div>
                    )}
                    {step === 2 && (
                        <div className="CreateAccount-info">
                            <span>
                                Настройте пересылку на этот акаунт <InfoNorm onClick={handleClickInfo} />
                            </span>
                            <span>{freeAccount?.email}</span>
                            <span>Сообщения:</span>
                            {messages.map((item) => (
                                <p>{item.body}</p>
                            ))}
                        </div>
                    )}
                </Row>
                <div className="CreateAccount-buttons">
                    <Button
                        style={{ background: 'silver' }}
                        className="wrapper-button-added-path-button"
                        variant="secondary"
                        onClick={closedModal}
                    >
                        Отмена
                    </Button>
                    <Button className="wrapper-button-added-path-button" variant="primary" onClick={factoryActionButton()}>
                        {isLoading ? <Spinner size="sm" animation="border" /> : showTitleButton()}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default ModalCreateAccount;
