import './styles.scss';
import { ReactComponent as Add } from '../../../assets/images/add.svg';
import { ReactComponent as Arrow } from '../../../assets/images/arrow.svg';
import { ReactComponent as ArrowSort } from '../../../assets/images/arrowSort.svg';
import { ReactComponent as Delete } from '../../../assets/images/delete.svg';
import { ReactComponent as Copy } from '../../../assets/images/copy.svg';
import { ReactComponent as Past } from '../../../assets/images/paste.svg';
import { useEffect, useState } from 'react';
import ModalAddedCriterion from '../../Modals/ModalAddedCriterion';
import OutlinedDropdown from '../../UI/LandingSelect';
import { Tooltip } from 'react-tooltip';
import { ReactComponent as Question } from '../../../assets/images/question.svg';
import { NotificationManager } from 'react-notifications';
import { vaultService } from '../../../core/services/vault-service';
import { nanoid } from 'nanoid';

const RulesBinom = ({ rules, setRules, ourСloak, optionsLandings, sub }) => {
    const [openRules, setOpenRules] = useState([]);
    const [isOpenAddedCriterion, setIsOpenAddedCriterion] = useState(false);
    const [rulesIndex, setRulesIndex] = useState(null);
    const [editRule, setEditRule] = useState(null);
    const [copyRule, setCopyRule] = useState(null);

    const service = vaultService();

    const openAddedCriterion = (indexRules) => {
        setRulesIndex(indexRules);
        setIsOpenAddedCriterion(true);
    };

    const handleChangeCheckBoxLandRules = (active, indexRules, indexLand) => {
        const value = active === 1 ? 0 : 1;
        const mapped = rules.map((item, index) => {
            if (index === indexRules) {
                const rule = item.result_page.map((el, indexCrit) => {
                    if (indexCrit === indexLand) {
                        return { ...el, active: value };
                    }
                    return el;
                });
                return { ...item, result_page: rule };
            }
            return item;
        });

        setRules(mapped);
    };

    const handleChangeSelectSub = (e, indexRule, indexLand) => {
        const value = e.value;
        if (e.label.includes('Service')) {
            const mapped = rules.map((item, index) => {
                if (index === indexRule) {
                    const mappedLand = item.result_page.map((el, indexLanding) => {
                        if (indexLanding === indexLand) {
                            return { ...el, result_type: value, data: e.label };
                        }
                        return el;
                    });
                    return { ...item, result_page: mappedLand };
                }
                return item;
            });

            setRules(mapped);

            return;
        }

        const mapped = rules.map((item, index) => {
            if (index === indexRule) {
                const mappedLand = item.result_page.map((el, indexLanding) => {
                    if (indexLanding === indexLand) {
                        return { ...el, result_type: value, data: el.data === 'BinomService' ? 'http://' : el.data };
                    }
                    return el;
                });
                return { ...item, result_page: mappedLand };
            }
            return item;
        });

        setRules(mapped);
    };

    const handleChangeInputSub = (e, indexRule, indexLand, key) => {
        if (key === 'weight') {
            const value = e.target.value;
            const REGEX_ONLY_NUMBERS = /^[0-9]+(\.[0-9]{0,2})?$/;
            if (value.match(REGEX_ONLY_NUMBERS) || value === '') {
                const mapped = rules.map((item, index) => {
                    if (index === indexRule) {
                        const mappedLand = item.result_page.map((el, indexLanding) => {
                            if (indexLanding === indexLand) {
                                return { ...el, [key]: value };
                            }
                            return el;
                        });
                        return { ...item, result_page: mappedLand };
                    }
                    return item;
                });

                setRules(mapped);
            }
            return;
        }
        const value = e.target.value;
        const mapped = rules.map((item, index) => {
            if (index === indexRule) {
                const mappedLand = item.result_page.map((el, indexLanding) => {
                    if (indexLanding === indexLand) {
                        return { ...el, [key]: value };
                    }
                    return el;
                });
                return { ...item, result_page: mappedLand };
            }
            return item;
        });

        setRules(mapped);
    };

    const addedNewLand = (indexRules) => {
        const land = { data: 'http://', active: 1, weight: 100, result_type: 1 };

        const mapped = rules.map((item, index) => {
            if (index === indexRules) {
                return {
                    ...item,
                    result_page: item?.result_page ? [...item.result_page, land] : [land],
                };
            }
            return item;
        });

        setRules(mapped);
    };

    const closeAddedCriterion = () => {
        setRulesIndex(null);
        setEditRule(null);
        setIsOpenAddedCriterion(false);
    };

    const addedCriterion = (criteria) => {
        criteria.id = nanoid();
        if (editRule) {
            const mapped = rules.map((item, index) => {
                if (index === rulesIndex) {
                    const criteries = item.filters.map((el) => {
                        if (el.id === editRule.id) {
                            return criteria;
                        }
                        return el;
                    });
                    return { ...item, filters: criteries };
                }
                return item;
            });

            setRules(mapped);

            return;
        }

        const mapped = rules.map((item, index) => {
            if (index === rulesIndex) {
                return {
                    ...item,
                    filters: item?.filters ? [...item?.filters, criteria] : [criteria],
                };
            }
            return item;
        });

        setRules(mapped);
    };
    const moveItemUpRulesLand = (rulesIndex, fromIndex, toIndex) => {
        const arrayland = rules.find((element, index) => index === rulesIndex);
        if (arrayland) {
            const newArray = [...arrayland.result_page];
            const item = newArray.splice(fromIndex, 1)[0];
            newArray.splice(toIndex, 0, item);

            if (toIndex === 0) {
                return;
            }

            const mapped = rules.map((element, index) => {
                if (index === rulesIndex) {
                    return { ...element, result_page: newArray };
                }
                return element;
            });

            setRules(mapped);
            return newArray;
        }
    };

    const moveItemDownRulesLand = (rulesIndex, fromIndex) => {
        const arrayland = rules.find((element, index) => index === rulesIndex);
        if (arrayland) {
            const toIndex = fromIndex + 1;
            if (toIndex >= arrayland.result_page.length) {
                return;
            }
            moveItemUpRulesLand(rulesIndex, fromIndex, toIndex);
        }
    };

    const moveItemUpRules = (fromIndex, toIndex) => {
        const newArray = [...rules];
        const item = newArray.splice(fromIndex, 1)[0];
        newArray.splice(toIndex, 0, item);

        if (toIndex === 0) {
            return;
        }

        setRules(newArray);
        return newArray;
    };

    const moveItemDownRules = (fromIndex) => {
        const toIndex = fromIndex + 1;
        if (toIndex >= rules.length) {
            return;
        }
        moveItemUpRules(fromIndex, toIndex);
    };

    const handleChangeInput = (e, indexRules, key) => {
        const mapped = rules.map((item, index) => {
            if (index === indexRules) {
                return { ...item, [key]: e.target.value };
            }
            return item;
        });

        setRules(mapped);
    };

    const handleChangeCheckBoxRules = (active, indexRules) => {
        const value = active === 1 ? 0 : 1;
        const mapped = rules.map((item, index) => {
            if (index === indexRules) {
                return { ...item, active: value };
            }
            return item;
        });

        setRules(mapped);
    };

    const handleChangeCheckBoxTarget = (active, indexRules) => {
        const value = active === 1 ? 0 : 1;
        const mapped = rules.map((item, index) => {
            if (index === indexRules) {
                return { ...item, target: value };
            }
            return item;
        });

        setRules(mapped);
    };

    const handleChangeCheckBoxSplit = (active, indexRules) => {
        const value = active === 1 ? 0 : 1;
        const mapped = rules.map((item, index) => {
            if (index === indexRules) {
                return { ...item, result_split: value };
            }
            return item;
        });

        setRules(mapped);
    };

    const handleChangeCheckBoxCriteriaRules = (active, indexRules, indexCriteria) => {
        const value = active === 1 ? 0 : 1;
        const mapped = rules.map((item, index) => {
            if (index === indexRules) {
                const rule = item.filters.map((el, indexCrit) => {
                    if (indexCrit === indexCriteria) {
                        return { ...el, active: value };
                    }
                    return el;
                });
                return { ...item, filters: rule };
            }
            return item;
        });

        setRules(mapped);
    };

    const handledeleteCriteriaRules = (indexRules, indexCriteria) => {
        const mapped = rules.map((item, index) => {
            if (index === indexRules) {
                const rule = item.filters.filter((el, indexCrit) => indexCrit !== indexCriteria);
                return { ...item, filters: rule };
            }
            return item;
        });

        setRules(mapped);
    };

    const handledeleteLandRules = (indexRules, indexLand) => {
        const mapped = rules.map((item, index) => {
            if (index === indexRules) {
                const rule = item.result_page.filter((el, indexCrit) => indexCrit !== indexLand);
                return { ...item, result_page: rule };
            }
            return item;
        });

        setRules(mapped);
    };

    const handleClickDeleteRules = (indexRules) => {
        const filtered = rules.filter((item, index) => index !== indexRules);

        setRules(filtered);
    };

    const handleClickEditCriteria = (criteria, indexRules) => {
        setEditRule(criteria);
        openAddedCriterion(indexRules);
    };

    const handleClickNewRule = () => {
        const rule = {
            active: 1,
            default: 0,
            name: 'Rule',
            result_split: 0,
            target: 1,
            filters: [],
            rules: null,
        };

        const item = { rule: rules.length, paths: [] };
        setOpenRules([...openRules, item]);

        setRules([...rules, rule]);
    };

    const showSubRules = (id) => {
        const ruleFind = openRules.find((item) => String(item.rule) === String(id));

        if (ruleFind) return true;
        return false;
    };

    const handleOpenRules = (indexRules) => {
        const ruleFind = openRules.find((item) => String(item.rule) === String(indexRules));
        if (ruleFind) {
            const filtered = openRules.filter((item) => String(item.rule) !== String(indexRules));
            setOpenRules(filtered);
            return;
        }
        const item = { rule: indexRules, paths: [] };
        setOpenRules([...openRules, item]);
    };

    const isArrayArrayOfObjects = (arr) => {
        return Array.isArray(arr) && arr.length > 0 && typeof arr[0] === 'object' && arr[0] !== null;
    };

    const showValues = (value) => {
        if (typeof value === 'string') return value;
        else if (typeof value === 'number') return value === 1 ? 'Yes' : 'No';
        else if (typeof value === 'object') {
            if (isArrayArrayOfObjects(value)) {
                return value
                    .map((item) => {
                        const key = Object.keys(item);
                        const value = item[key];
                        return `${key}: ${value}`;
                    })
                    ?.join(',');
            }
            return value?.join(',');
        }
        return '';
    };

    const valueType = (id) => {
        const el = optionsLandings.find((item) => item.value === id);

        if (el) {
            return el;
        }

        return null;
    };

    const showInfoRules = () => {
        if (sub || ourСloak) return null;
        return (
            <div className="info_norm_buyer">
                <Question data-tooltip-id="question-tooltip-rules" className="question-icon" />
                <Tooltip
                    id="question-tooltip-rules"
                    place="bottom"
                    style={{
                        zIndex: '9999999999',
                        margin: 0,
                        padding: '10px',
                        width: '250px',
                    }}
                >
                    <div className="info_norm_buyer-content">
                        <p className="m-0 p-0 w-100">
                            В этом блоке вы можете добавить одну или несколько групп фильтров для выполнения фильтрации и
                            настройки разделений, если это необходимо. Группы можно переименовывать и задавать нужные параметры.
                            Примечание: при активации элемента Landing возможность добавлять Sub Group исчезнет, ​​чтобы вернуть
                            эту возможность, очистите созданные Landings. В обратном порядке это работает идентично.
                        </p>
                    </div>
                </Tooltip>
            </div>
        );
    };

    const showInfoCriteria = () => {
        return (
            <div className="info_norm_buyer">
                <Question data-tooltip-id="question-tooltip-criteria" className="question-icon" />
                <Tooltip
                    id="question-tooltip-criteria"
                    place="bottom"
                    style={{
                        zIndex: '9999999999',
                        margin: 0,
                        padding: '10px',
                        width: '250px',
                    }}
                >
                    <div className="info_norm_buyer-content">
                        <p className="m-0 p-0 w-100">
                            Здесь заполняется и настраивается структура фильтра для выполнения фильтрации. Примечание: настройте
                            фильтры правильно, и если у вас есть общие фильтры в группах, объедините их и удалите различия в
                            подгруппах
                        </p>
                    </div>
                </Tooltip>
            </div>
        );
    };

    const showInfoLanding = () => {
        return (
            <div className="info_norm_buyer">
                <Question data-tooltip-id="question-tooltip-landing" className="question-icon" />
                <Tooltip
                    id="question-tooltip-landing"
                    place="bottom"
                    style={{
                        zIndex: '9999999999',
                        margin: 0,
                        padding: '10px',
                        width: '250px',
                    }}
                >
                    <div className="info_norm_buyer-content">
                        <p className="m-0 p-0 w-100">
                            Блок, в котором заполняется список Landings. Одновременно может быть заполнен либо этот блок, либо
                            блок Sub Groups.
                        </p>
                    </div>
                </Tooltip>
            </div>
        );
    };

    const showInfoSubRules = () => {
        return (
            <div className="info_norm_buyer">
                <Question data-tooltip-id="question-tooltip-sub-rules" className="question-icon" />
                <Tooltip
                    id="question-tooltip-sub-rules"
                    place="bottom"
                    style={{
                        zIndex: '9999999999',
                        margin: 0,
                        padding: '10px',
                        width: '250px',
                    }}
                >
                    <div className="info_norm_buyer-content">
                        <p className="m-0 p-0 w-100">
                            Здесь можно создавать вложенные фильтры для реализации гибких настроек фильтрации. Внутренние блоки
                            будут идентичны более высокому уровню создания группы. Одновременно может быть заполнен либо этот
                            блок, либо блок Landings.
                        </p>
                    </div>
                </Tooltip>
            </div>
        );
    };

    const showInfoNormal = () => {
        return (
            <div className="info_norm_buyer">
                <Question data-tooltip-id="question-tooltip-normal" className="question-icon" />
                <Tooltip
                    id="question-tooltip-normal"
                    place="bottom"
                    style={{
                        zIndex: '9999999999',
                        margin: 0,
                        padding: '10px',
                        width: '250px',
                    }}
                >
                    <div className="info_norm_buyer-content">
                        <p className="m-0 p-0 w-100">Поочередная выдача страниц</p>
                    </div>
                </Tooltip>
            </div>
        );
    };

    const showInfoSplit = () => {
        return (
            <div className="info_norm_buyer">
                <Question data-tooltip-id="question-tooltip-split" className="question-icon" />
                <Tooltip
                    id="question-tooltip-split"
                    place="bottom"
                    style={{
                        zIndex: '9999999999',
                        margin: 0,
                        padding: '10px',
                        width: '250px',
                    }}
                >
                    <div className="info_norm_buyer-content">
                        <p className="m-0 p-0 w-100">Раздельная выдача страниц</p>
                    </div>
                </Tooltip>
            </div>
        );
    };

    const showInfoResult = () => {
        return (
            <div className="info_norm_buyer">
                <Question data-tooltip-id="question-tooltip-result" className="question-icon" />
                <Tooltip
                    id="question-tooltip-result"
                    place="bottom"
                    style={{
                        zIndex: '9999999999',
                        margin: 0,
                        padding: '10px',
                        width: '250px',
                    }}
                >
                    <div className="info_norm_buyer-content">
                        <p className="m-0 p-0 w-100">
                            Результат может быть отображен в нескольких типах: содержимое страницы, один из доступных редиректов.
                            Также есть специальные типы, которые доступны при активации сервисов (они имеют Service в названии)
                        </p>
                    </div>
                </Tooltip>
            </div>
        );
    };

    const updateCopyRule = () => {
        const rule = service.getItem('copy-rules');
        if (rule) {
            setCopyRule(rule);
        }
    };

    useEffect(() => {
        updateCopyRule();
        const handleStorageChange = (event) => {
            if (event.key === 'copy-rules') {
                updateCopyRule();
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    const handleClickCopyRules = (item) => {
        service.setItem('copy-rules', item);
        setCopyRule(item);
        NotificationManager.success(`Успешно скопировано`, '', 3500);
    };

    const handleClickPasteRules = () => {
        const rule = service.getItem('copy-rules');
        const newRule = { ...rule, name: `${rule.name} copy` };
        const item = { rule: rules.length, paths: [] };
        setOpenRules([...openRules, item]);
        setRules([...rules, newRule]);
    };

    return (
        <div className={`Rules ${sub && 'pt-0 pl-0 pr-0'}`}>
            <div className={`Rules-header ${sub && 'd-none'} d-flex align-items-center gap-1`}>
                {!sub && <h3>{ourСloak ? 'Our Cloack' : 'Groups'}</h3>}
                {showInfoRules()}
            </div>
            <div className="Rules-container">
                <div className="Rules-container-header">
                    <div className="Rules-container-header-title">
                        <Arrow />
                        <span>{ourСloak ? 'Our Cloack' : sub ? 'Sub Groups' : 'Groups'}</span>
                        {sub && showInfoSubRules()}
                    </div>
                    <span></span>
                    <span>{copyRule && <Past onClick={handleClickPasteRules} className="Rules-copy-icon" />} Active</span>
                </div>
                <div className="Rules-container-content">
                    {rules !== null &&
                        rules.map((item, indexRules) => (
                            <div key={indexRules} className="Rules-container-content-wrapper">
                                <div className="Rules-container-content-item">
                                    <div className="Rules-container-content-item-title">
                                        <div className="Rules-container-content-item-title-stripe" />
                                        <Arrow
                                            className={showSubRules(indexRules) ? 'arrow-active' : 'arrow'}
                                            onClick={() => handleOpenRules(indexRules)}
                                        />
                                        <input
                                            className="input-edit"
                                            type="text"
                                            value={item.name}
                                            onChange={(e) => handleChangeInput(e, indexRules, 'name')}
                                        />
                                    </div>
                                    <div className="Rules-container-content-item-icons">
                                        <Copy onClick={() => handleClickCopyRules(item)} className="Rules-copy-icon" />
                                        {!ourСloak && (
                                            <div>
                                                <ArrowSort
                                                    onClick={() => moveItemUpRules(indexRules - 1, indexRules)}
                                                    className="arrow-sort"
                                                />
                                                <ArrowSort
                                                    onClick={() => moveItemDownRules(indexRules)}
                                                    className="arrow-sort-reverse"
                                                />
                                            </div>
                                        )}
                                        {!ourСloak && (
                                            <Delete
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => handleClickDeleteRules(indexRules)}
                                            />
                                        )}
                                    </div>
                                    <div className="Rules-container-content-item-active">
                                        <div className="form-check form-switch">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                role="switch"
                                                id="flexSwitchCheckChecked"
                                                checked={item.active === 1 ? true : false}
                                                onChange={() => handleChangeCheckBoxRules(item.active, indexRules)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {showSubRules(indexRules) && (
                                    <div className="Rules-container-sub">
                                        <div className="Rules-container-sub-criteria">
                                            <div className="d-flex align-items-center gap-2 mb-2">
                                                <h4 className="m-0">Filters</h4>
                                                {showInfoCriteria()}
                                            </div>

                                            {(item.filters || []).map((el, indexCriteria) => (
                                                <div key={indexCriteria} className="Rules-container-content-item">
                                                    <div className="Rules-container-content-item-title">
                                                        <div className="Rules-container-content-item-title-stripe" />
                                                        <span
                                                            style={{
                                                                cursor: 'pointer',
                                                                width: '20%',
                                                            }}
                                                            onClick={() => handleClickEditCriteria(el, indexRules)}
                                                        >
                                                            {el.filter.name} {el.operator.name}{' '}
                                                            <div className="Values">{showValues(el.value)}</div>
                                                        </span>
                                                    </div>

                                                    <div className="Rules-container-content-item-icons">
                                                        <Delete
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => handledeleteCriteriaRules(indexRules, indexCriteria)}
                                                        />
                                                    </div>
                                                    <div className="Rules-container-content-item-active">
                                                        <div className="form-check form-switch">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                role="switch"
                                                                id="flexSwitchCheckChecked"
                                                                checked={el.active === 1 ? true : false}
                                                                onChange={(e) =>
                                                                    handleChangeCheckBoxCriteriaRules(
                                                                        el.active,
                                                                        indexRules,
                                                                        indexCriteria,
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                            <div className="wrapper-button-added-path">
                                                <button
                                                    onClick={() => openAddedCriterion(indexRules)}
                                                    className="wrapper-button-added-path-button"
                                                >
                                                    <span>Filter</span>
                                                    <Add />
                                                </button>
                                            </div>
                                        </div>
                                        {!ourСloak && (!item.rules || item.rules?.length === 0) && (
                                            <div className="Rules-container-sub-criteria">
                                                <div className="Rules-container-landings">
                                                    <div className="d-flex align-items-center gap-2">
                                                        <h4>Landings</h4>
                                                        {showInfoLanding()}
                                                    </div>
                                                    <div className="Rules-container-content-item-split">
                                                        <div className="d-flex align-items-center gap-2">
                                                            {showInfoNormal()}
                                                            <span>Normal</span>
                                                        </div>
                                                        <div className="form-check form-switch">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                role="switch"
                                                                id="flexSwitchCheckChecked"
                                                                checked={item.result_split === 1 ? true : false}
                                                                onChange={() =>
                                                                    handleChangeCheckBoxSplit(item.result_split, indexRules)
                                                                }
                                                            />
                                                        </div>
                                                        <div className="d-flex align-items-center gap-2">
                                                            <span>Split</span>
                                                            {showInfoSplit()}
                                                        </div>
                                                    </div>
                                                </div>
                                                {(item.result_page || []).map((el, indexLand) => (
                                                    <div key={indexLand} className="Rules-container-content-item">
                                                        <div className="Rules-container-content-item-title">
                                                            <div className="Rules-container-content-item-title-stripe" />
                                                            <input
                                                                className="input-edit"
                                                                type="text"
                                                                value={el?.data}
                                                                disabled={valueType(el?.result_type)?.label?.includes('Service')}
                                                                onChange={(e) =>
                                                                    handleChangeInputSub(e, indexRules, indexLand, 'data')
                                                                }
                                                            />
                                                        </div>
                                                        <div className="Rules-container-content-item-icons">
                                                            {!ourСloak && (
                                                                <div className="d-flex align-items-center gap-2">
                                                                    {showInfoResult()}
                                                                    <OutlinedDropdown
                                                                        onSelect={(e) =>
                                                                            handleChangeSelectSub(e, indexRules, indexLand)
                                                                        }
                                                                        options={optionsLandings}
                                                                        defaultSelected={valueType(el?.result_type)}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                        {item.result_split === 1 && (
                                                            <div className="Rules-container-content-item-weight">
                                                                <input
                                                                    className="input-edit-weight"
                                                                    type="text"
                                                                    value={el?.weight}
                                                                    onChange={(e) =>
                                                                        handleChangeInputSub(e, indexRules, indexLand, 'weight')
                                                                    }
                                                                />
                                                            </div>
                                                        )}
                                                        <div className="Rules-container-content-item-icons">
                                                            <Delete
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={() => handledeleteLandRules(indexRules, indexLand)}
                                                            />
                                                        </div>
                                                        <div className="Rules-container-content-item-active">
                                                            <div className="form-check form-switch">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    role="switch"
                                                                    id="flexSwitchCheckChecked"
                                                                    checked={el?.active === 1 ? true : false}
                                                                    onChange={(e) =>
                                                                        handleChangeCheckBoxLandRules(
                                                                            el?.active,
                                                                            indexRules,
                                                                            indexLand,
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                                {(!item.rules || item.rules?.length === 0) && (
                                                    <div className="wrapper-button-added-path">
                                                        <button
                                                            onClick={() => addedNewLand(indexRules)}
                                                            className="wrapper-button-added-path-button"
                                                        >
                                                            <span>Landing</span>
                                                            <Add />
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        {!ourСloak && (!item.result_page || item.result_page?.length === 0) && (
                                            // <div className="Rules-container-sub-rules">
                                            <RulesBinom
                                                optionsLandings={optionsLandings}
                                                rules={item.rules ?? []}
                                                sub
                                                setRules={(newSubRules) => {
                                                    const updatedRules = rules.map((rule, i) => {
                                                        if (i === indexRules) {
                                                            return { ...rule, rules: newSubRules };
                                                        }
                                                        return rule;
                                                    });
                                                    setRules(updatedRules);
                                                }}
                                            />
                                            // </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        ))}
                    {!ourСloak && (
                        <div className="wrapper-button-added-path">
                            <button onClick={handleClickNewRule} className="wrapper-button-added-path-button">
                                <span>Group</span>
                                <Add />
                            </button>
                        </div>
                    )}
                </div>
            </div>
            {isOpenAddedCriterion && (
                <ModalAddedCriterion
                    show={isOpenAddedCriterion}
                    closeModal={closeAddedCriterion}
                    addedCriterion={addedCriterion}
                    editRule={editRule}
                />
            )}
        </div>
    );
};

export default RulesBinom;
